@import "nes.css/css/nes.css";

$title-width: 383px;
$title-height: 320px;
$grass-width: 256px;
$boy-width: 59px;
$girl-width: 62px;

html,
body,
pre,
code,
kbd,
samp {
  font-family: "Press Start 2P", monospace;
}

html,
body {
  overflow-x: hidden;
}

body,
.nes-container.with-title > .title {
  background: #d6f7ff;
}

html {
  @for $i from 8 through 1 {
    @media (max-width: ($title-width * $i)) and (max-height: ($title-height * $i)) {
      --zoom: #{$i};
    }
  }
}

.is-dark {
  color: white;
}

.is-light {
  color: black;
}

.bg-black {
  background-color: #000;
}

.bg-green {
  background-color: #086340;
}

.bg-green-transition {
  background: linear-gradient(#086340, #385830 80%);
}

.bg-blue {
  background-color: #6058f9;
}

.section-background {
  position: relative;

  .nes-container {
    background: none;

    &.bg-black {
      background-color: #000;
    }
  }

  & > .section-centered {
    margin: 0 auto;
    padding: 2rem 0;
    width: min(80vh, calc(100% - 1rem));

    & > h1 {
      font-size: 1.5em;
      display: flex;
      align-items: center;
      column-gap: 1rem;

      &:not(:first-of-type) {
        margin-top: 5rem;
      }
    }
  }
}

.nes-container.with-title.is-centered > .title {
  margin-top: -2.3rem;
}

.message-list {
  .message-left,
  .message-right {
    display: flex;
    align-items: flex-end;
    color: black;
  }

  .message-left {
    text-align: left;
  }

  .message-right {
    justify-content: flex-end;
    text-align: right;
  }
}

.mb1 {
  margin-bottom: 1rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.mb3 {
  margin-bottom: 3rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 5rem;
}

.pb3 {
  padding-bottom: 3rem;
}

@keyframes move-up-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

@keyframes move-left-right {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

.title-screen {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;

  img {
    user-select: none;
    pointer-events: none;
  }

  .bg {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc($title-width * var(--zoom));
  }

  .boy {
    width: calc($boy-width * var(--zoom));
    animation: move-up-down 1s ease infinite;
  }

  .girl {
    width: calc($girl-width * var(--zoom));
    animation: move-up-down 1s ease infinite;
    animation-delay: 0.1s;
  }

  .characters {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .grass {
    position: fixed;
    bottom: 0;
    display: flex;
    animation: move-left-right 10s linear infinite;

    img {
      width: calc($grass-width * var(--zoom));
    }

    &.scrolling {
      position: absolute;
    }
  }

  .nes-container {
    position: fixed;
    max-width: min(30em, calc(100% - 4rem));
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #d6f7ff;
  }
}

img.pixelated,
.pixelated img {
  image-rendering: pixelated;
}

.photo-album {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(224px * var(--zoom));
  min-height: calc(144px * var(--zoom));
  margin: 0 auto;

  .bg {
    position: absolute;
    width: calc(112px * var(--zoom));
  }

  .right .bg {
    transform: scale(-1, 1);
  }

  .photos {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(112px * var(--zoom));
    height: calc(144px * var(--zoom));
    column-gap: calc(12px * var(--zoom));
    row-gap: calc(14px * var(--zoom));
    padding: calc(13px * var(--zoom)) calc(10px * var(--zoom)) 0
      calc(18px * var(--zoom));

    div {
      width: calc(36px * var(--zoom));
      height: calc(26px * var(--zoom));
      overflow: hidden;
      position: relative;

      & > * {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }
    }
  }

  .right .photos {
    padding: calc(13px * var(--zoom)) calc(18px * var(--zoom)) 0
      calc(10px * var(--zoom));
  }
}

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 2vh;
  background: rgba(0, 0, 0, 0.6);

  & > div {
    display: contents;

    & > * {
      position: relative;
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

$wave-width: 128px;

.wave-container {
  @for $i from 10 through 1 {
    @media (max-width: ($wave-width * $i * 2)) {
      --wave-zoom: #{$i};
    }
  }

  position: relative;
  height: calc((64px + 13.5px) * var(--wave-zoom));

  .wave {
    position: absolute;
    right: 0;
    top: 0;
    height: calc(64px * var(--wave-zoom));
    width: calc(128px * var(--wave-zoom) * 2);
  }

  .badge {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: calc(27px * var(--wave-zoom));
    width: calc(129px * var(--wave-zoom));
    margin: 0 auto;
  }

  .bg-blue {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(13.5px * var(--wave-zoom));
  }
}

$starter-width: 160px;

.starter-container {
  @for $i from 10 through 1 {
    @media (max-width: ($starter-width * $i * 2)) {
      --starter-zoom: #{$i};
    }
  }

  position: relative;
  width: calc($starter-width * var(--starter-zoom));
  height: calc((80px + 30px) * var(--starter-zoom));
  margin: 0 auto;

  .bg {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .bag {
    width: calc(110px * var(--starter-zoom));
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .ball {
    width: calc(22px * var(--starter-zoom));
    position: absolute;
    bottom: calc(20px * var(--starter-zoom));
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .textbox,
  .textbox-text {
    position: absolute;
    top: calc(25px * var(--starter-zoom));
    left: 0;
    right: 0;
    margin: 0 auto;
    width: max(18ch, calc(206px * 0.5 * var(--starter-zoom)));
    height: calc(46px * 0.5 * var(--starter-zoom));
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
}

.footer-placeholder {
  height: 90vh;
}

.radio-row label {
  margin-right: 2em;
}

.nes-list li {
  margin-bottom: 1em;
}

.nes-list.is-circle li::before {
  top: 3px;
}

.nes-list.is-circle.is-dark li::before {
  color: white;
}

$thanks-width: 256px;

.thanks-container {
  @for $i from 4 through 3 {
    @media (max-width: ($thanks-width * $i * 2)) {
      --thanks-zoom: #{$i};
    }
  }

  background-color: #e9f8c1;
  position: relative;
  height: calc(192px * var(--thanks-zoom));
  display: flex;
  flex-direction: column;
  align-items: center;

  .frame {
    height: calc(192px * var(--thanks-zoom));
    width: calc(256px * var(--thanks-zoom));
    display: block;
  }

  .content {
    height: calc(100px * var(--thanks-zoom));
    width: calc(166px * var(--thanks-zoom));
    margin: calc((36px - 192px) * var(--thanks-zoom)) auto 0;
    display: block;
    font-size: calc(0.75rem + (var(--thanks-zoom) - 3) * 0.25rem);
    max-width: 95vw;
  }

  $photo-size: 34px;

  .thanks-photo {
    height: calc($photo-size * var(--thanks-zoom));
    border-radius: 50%;
    display: block;
    position: absolute;
    bottom: calc(10px * var(--thanks-zoom));
    left: calc(50% - $photo-size / 2 * var(--thanks-zoom));
  }

  .badge {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: calc(27px * var(--thanks-zoom));
    width: calc(129px * var(--thanks-zoom));
    margin: 0 auto;
  }

  .bg-blue {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(13.5px * var(--thanks-zoom));
  }
}

@media (max-width: 400px) {
  .section-background {
    .nes-container,
    .nes-container.is-rounded,
    .nes-container.is-dark,
    .nes-container.is-rounded.is-dark {
      border: none;
      border-image: none;
      padding: 0;
    }
  }

  .nes-balloon {
    padding: 1rem 0.5rem;

    &.from-left {
      margin-left: -2rem;
    }

    &.from-right {
      margin-right: -1.5rem;
    }
  }
}

.nes-table.is-dark {
  background: none;

  tr td:last-of-type {
    width: 100%;
  }

  td p:last-of-type {
    margin-bottom: 0;
  }
}

.popup-message {
  transition: transform 0.4s ease-in-out;
  transform: translateY(0) scale(1);

  &.hidden {
    transform: translateY(calc(60px * var(--starter-zoom))) scale(0);
  }
}

// Somehow prevents clicking if not removed.
.nes-table.is-dark::before {
  position: relative;
  content: none;
}

a:any-link {
  color: inherit;
  text-decoration: underline;
}

.nes-badge {
  user-select: initial;
}
